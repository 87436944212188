import { Fragment, useMemo } from "react";
import { useBanner, useBannerButtonCallbacks, BannerButton } from "../../..";

const BannerButtonList = () => {
  const banner = useBanner();
  const {
    activeAction,
    bodyDesign: {
      acceptEssentialsUseAcceptAll,
      acceptAllOneRowLayout
    },
    decision: {
      showGroups,
      groupsFirstView,
      saveButton,
      acceptAll,
      acceptEssentials,
      acceptIndividual,
      buttonOrder: buttonOrderString
    },
    texts: {
      acceptAll: acceptAllText,
      acceptEssentials: acceptEssentialsText,
      acceptIndividual: acceptIndividualText
    },
    saveButton: {
      type: saveButtonType,
      useAcceptAll: saveButtonUseAcceptAll
    },
    individualTexts: {
      save
    },
    individualPrivacyOpen,
    didGroupFirstChange,
    productionNotice,
    buttonClicked = ""
  } = banner; // Calculate order of the buttons depending on a string like `all,essential,save,individual`

  const {
    all: buttonOrderAll,
    essential: buttonOrderEssential,
    individual: buttonOrderIndividual,
    save: buttonOrderSave
  } = useMemo(() => {
    const buttonOrder = buttonOrderString.split(",");
    const buttonToOrder = buttonOrder.reduce((previous, curr) => {
      previous[curr] = buttonOrder.indexOf(curr);
      return previous;
    }, {});
    const orderToButton = buttonOrder.reduce((previous, curr) => {
      previous[buttonOrder.indexOf(curr)] = curr;
      return previous;
    }, {}); // If one-row layout is enabled, reverse the first two orders so the first button gets moved to the right

    if (acceptAllOneRowLayout) {
      buttonToOrder[orderToButton[0]] = 1;
      buttonToOrder[orderToButton[1]] = 0;
    }

    return buttonToOrder;
  }, [buttonOrderString, acceptAllOneRowLayout]);
  const isPro = process.env.PLUGIN_CTX === "pro";
  const {
    buttonClickedAll,
    buttonClickedEssentials,
    buttonClickedCustom,
    acceptAll: handleAll,
    acceptEssentials: handleEssentials,
    acceptIndividual: handleIndividual,
    openIndividualPrivacy
  } = useBannerButtonCallbacks(); // We expect a reload of the page for the "change" action (keeps also dialog open)

  const busyOnClick = activeAction === "change";
  const groupsFirstViewEnabled = showGroups && groupsFirstView;
  return h(Fragment, null, !individualPrivacyOpen && isPro && groupsFirstViewEnabled && saveButton === "afterChangeAll" && didGroupFirstChange ? h(BannerButton, {
    onClick: handleIndividual,
    type: acceptAll,
    inlineStyle: "acceptAll",
    framed: buttonClicked === buttonClickedCustom,
    busyOnClick: busyOnClick,
    order: buttonOrderAll
  }, save) : h(BannerButton, {
    onClick: handleAll,
    type: acceptAll,
    inlineStyle: "acceptAll",
    framed: buttonClicked === buttonClickedAll,
    busyOnClick: busyOnClick,
    order: buttonOrderAll
  }, acceptAllText), activeAction !== "change" && h(BannerButton, {
    onClick: handleEssentials,
    type: acceptEssentialsUseAcceptAll ? acceptAll : acceptEssentials,
    inlineStyle: acceptEssentialsUseAcceptAll ? "acceptAll" : "acceptEssentials",
    framed: buttonClicked === buttonClickedEssentials,
    busyOnClick: busyOnClick,
    order: buttonOrderEssential
  }, acceptEssentialsText), (individualPrivacyOpen || isPro && groupsFirstViewEnabled && (saveButton === "always" || saveButton === "afterChange" && didGroupFirstChange)) && h(BannerButton, {
    onClick: handleIndividual,
    type: saveButtonUseAcceptAll ? acceptAll : saveButtonType,
    inlineStyle: saveButtonUseAcceptAll ? "acceptAll" : "save",
    framed: buttonClicked === buttonClickedCustom,
    busyOnClick: busyOnClick,
    order: buttonOrderSave
  }, save), !individualPrivacyOpen && h(BannerButton, {
    type: acceptIndividual,
    onClick: openIndividualPrivacy,
    inlineStyle: "acceptIndividual",
    framed: buttonClicked.startsWith("ind_"),
    busyOnClick: busyOnClick,
    order: buttonOrderIndividual
  }, acceptIndividualText), productionNotice);
};

export { BannerButtonList };