import { putHtmlCodeToDom } from "@devowl-wp/headless-content-unblocker";
import { getServiceSelection } from "../decision";
/**
 * Opt-in and opt-out to a set of services for a given data manager like GTM / MTM.
 */

async function applyOptInOutManager(options, dataLayer, isManagerOptOut) {
  const waitPromises = [];
  /* onlypro:start */

  const {
    isManagerActive,
    iterateServices
  } = getServiceSelection(options); // When there is a manager, let's push all opt-in and opt-out events (parallel)

  if (dataLayer) {
    // Opt-in events
    iterateServices((group, service, isOptIn, {
      serviceIsManager
    }) => {
      const event = service.tagManagerOptInEventName;

      if (isOptIn && event && !serviceIsManager) {
        dataLayer.push({
          realCookieBannerOptInEvents: {
            [event]: true
          },
          event
        });
      }
    }); // Wait x seconds and apply opt-out events (should we add this to `waitPromises`?! I do not think so)

    setTimeout(() => iterateServices((group, service, isOptIn, {
      serviceIsManager
    }) => {
      const event = service.tagManagerOptOutEventName;

      if (!isOptIn && event && !serviceIsManager) {
        dataLayer.push({
          realCookieBannerOptOutEvents: {
            [event]: true
          },
          event
        });
      }
    }), 1000);
  } else if (isManagerActive && isManagerOptOut) {
    // The manager got opt out, let's execute scripts that are explicitly only for this case (sequentially)
    await iterateServices(async (group, {
      codeDynamics,
      codeOptIn,
      executeCodeOptInWhenNoTagManagerConsentIsGiven
    }, isOptIn) => {
      if (isOptIn && executeCodeOptInWhenNoTagManagerConsentIsGiven) {
        waitPromises.push(putHtmlCodeToDom(codeOptIn, codeDynamics));
      }
    });
  }
  /* onlypro:false */


  return {
    ready: Promise.all(waitPromises)
  };
}

export { applyOptInOutManager };