import { useMemo, useRef, Fragment } from "react";
import { useRect } from "@reach/rect";
import { usePlainCss } from "../hooks";
import { Portal, Tooltip } from ".";
let counter = 0;
/**
 * A portaled close icon creates a close icon rendered to a given container. This allows
 * you to put the close icon on the top right corner of an `overflow:hidden` container and
 * the tooltip gets rendered correctly.
 */

const CloseIcon = ({
  width,
  color,
  tooltipText,
  framed,
  renderInContainer,
  tooltipAlways,
  onClick,
  thickness = 1
}) => {
  const idCloseIconNotPortaled = useMemo(() => "react-close-icon-".concat((counter++).toString(36)), []);
  const idCloseIconPortaled = useMemo(() => "react-close-icon-".concat((counter++).toString(36)), []);
  /**
   * Why two containers for a simple close icon? I need to explain something technical here.
   * Our complete banner/dialog is tainted with `overflow:hidden` due to flickering effects
   * with animate.css etc. We will now create an invisible element with the placeholder,
   * we will then read its positioning (rect) and generate the visible close icon based on this position.
   */

  const idCloseIconPlaceholder = useMemo(() => "react-close-icon-".concat((counter++).toString(36)), []);
  const ref = useRef();
  const rect = useRect(ref);
  usePlainCss("#".concat(idCloseIconPlaceholder, ", #").concat(idCloseIconNotPortaled, " {\n position: absolute;\n top: 50%;\n right: 0;\n margin-top: calc(").concat(width, "px / 2 * -1);\n display: block;\n width: ").concat(width, "px;\n height: ").concat(width, "px; \n pointer-events: none;\n}\n\n#").concat(idCloseIconNotPortaled, " {\n  position: absolute !important;\n}\n\n#").concat(idCloseIconNotPortaled, ", #").concat(idCloseIconPortaled, " {\n cursor: pointer;\n pointer-events: all;\n ").concat(framed ? "outline: rgb(255, 94, 94) solid 5px;" : "", "\n}\n\n#").concat(idCloseIconPortaled, " {\n position: fixed !important;\n ").concat(rect ? "top: ".concat(rect.y, "px;left: ").concat(rect.x, "px;display:block;") : "display:none;", "\n width: ").concat(width, "px;\n height: ").concat(width, "px;\n z-index: 99;\n}\n#").concat(idCloseIconPortaled, ":after, #").concat(idCloseIconNotPortaled, ":after {\n  width: auto !important;\n  white-space: nowrap !important;\n}\n#").concat(idCloseIconPortaled, ">span:before, #").concat(idCloseIconPortaled, ">span:after,\n#").concat(idCloseIconNotPortaled, ">span:before, #").concat(idCloseIconNotPortaled, ">span:after {\n position: absolute;\n top: 50%;\n left: 50%;\n width: ").concat(thickness, "px;\n height: ").concat(width, "px;\n background-color: ").concat(color, ";\n transform: rotate(45deg) translate(-50%, -50%);\n transform-origin: top left;\n transition: all 420ms;\n content: '';\n opacity: 0.5;\n pointer-events: none;\n}\n#").concat(idCloseIconPortaled, ">span:after,\n#").concat(idCloseIconNotPortaled, ">span:after {\n transform: rotate(-45deg) translate(-50%, -50%);\n}\n#").concat(idCloseIconPortaled, ">span:hover:before, #").concat(idCloseIconPortaled, ">span:hover:after,\n#").concat(idCloseIconNotPortaled, ">span:hover:before, #").concat(idCloseIconNotPortaled, ">span:hover:after {\n opacity: 1;\n width: ").concat(thickness + 1, "px;\n}"));
  return renderInContainer ? h(Fragment, null, h("div", {
    id: idCloseIconPlaceholder,
    ref: ref
  }), (rect === null || rect === void 0 ? void 0 : rect.y) > 0 && h(Portal, {
    renderInContainer: renderInContainer
  }, h(Tooltip, {
    title: tooltipText,
    id: idCloseIconPortaled,
    onClick: onClick,
    position: rect.y > 50 ? "top-left" : "left",
    always: tooltipAlways
  }, h("span", null)))) : h(Tooltip, {
    title: tooltipText,
    id: idCloseIconNotPortaled,
    onClick: onClick,
    position: "top-left",
    always: tooltipAlways
  }, h("span", null));
};

export { CloseIcon };