import _extends from "@babel/runtime/helpers/extends";
import { useState, useLayoutEffect, useEffect } from "react";
import { dispatchResizeEvent } from "@devowl-wp/headless-content-unblocker";
import { useBanner, useBannerActionLinks, useBannerHistory, usePlainCss, useRestrictScrollWhenVisible, bannerOverlayInlineStyle, bannerOverlayInlineStyleAlignment, BannerAnimatedContent, isHiddenDueLegal } from "../..";

const Banner = () => {
  const banner = useBanner();
  const {
    layout: {
      overlay,
      overlayBlur
    },
    customCss: {
      antiAdBlocker,
      css
    },
    visible,
    animationVisible,
    activeAction,
    skipOverlay,
    gotHiddenDueLegal,
    pageRequestUuid4,
    individualPrivacyOpen
  } = banner;
  const hiddenDueLegal = isHiddenDueLegal(banner);
  const show = visible && !hiddenDueLegal || !!activeAction;
  usePlainCss(antiAdBlocker ? css : "");
  usePlainCss( // `-webkit-text-size-adjust:100%;`: https://stackoverflow.com/a/2711132/5506547
  "#".concat(pageRequestUuid4, ", #").concat(pageRequestUuid4, " * {box-sizing: border-box;backface-visibility:initial;text-transform:initial;-webkit-text-size-adjust:100%;}")); // Blur effect

  if (process.env.PLUGIN_CTX === "pro") {
    /* onlypro:start */
    // Compatibility with selectors which move the overlay container of the cookie banner under another container down to `<body`
    // and causing blurring to the cookie banner itself.
    const offCanvasWrapperSelectors = [// [Plugin Comp] https://powerpackelements.com/elementor-widgets/offcanvas-content/
    ".pp-offcanvas-container", // [Plugin Comp] https://wordpress.org/plugins/mobile-menu/
    ".mobmenu-push-wrap", // [Plugin Comp] https://github.com/FrDH/mmenu-js
    ".mm-page", // [Plugin Comp] https://premiumaddons.com/docs/magic-section-widget-tutorial/
    ".premium-magic-section-body-inner", // [Plugin Comp] https://wordpress.org/plugins/groovy-menu-free/
    ".gm-nav-content-wrapper", // [Plugin Comp] https://essential-addons.com/elementor/docs/content-elements/essential-addons-elementor-offcanvas/
    ".eael-offcanvas-container"];
    usePlainCss(show && animationVisible && overlay && +overlayBlur > 0 ? ["body", // [Plugin Comp] https://bookingkit.net/de/
    "#bkBgKeepPos"].concat(offCanvasWrapperSelectors).map(bodyId => "".concat(bodyId, " > :not(").concat(["#".concat(pageRequestUuid4), ".rcb-customize-banner-container", // [Plugin Comp] https://bookingkit.net/de/
    "#bkBgScrollPrevent", // [Plugin Comp] TranslatePress
    "#trp_ald_modal_container"].concat(offCanvasWrapperSelectors).join(","), ") { filter: blur(").concat(overlayBlur, "px); }")).join("") : "");
    /* onlypro:end */
  }

  useRestrictScrollWhenVisible(show && overlay && animationVisible); // Listen to window resizes and resize the content automatically

  const [, setInvalidate] = useState(0);

  const updateSize = () => setInvalidate(+new Date());

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  useLayoutEffect(() => {
    dispatchResizeEvent(1000);
  }, [animationVisible, individualPrivacyOpen]); // When changes to the legal settings are done, make the banner visible or hide

  gotHiddenDueLegal && useEffect(() => {
    gotHiddenDueLegal(hiddenDueLegal);
  }, [hiddenDueLegal]);
  useBannerHistory();
  useBannerActionLinks();

  if (!show) {
    return null;
  }

  const content = h("div", bannerOverlayInlineStyleAlignment(banner), h(BannerAnimatedContent, null));
  return skipOverlay ? content : h("div", _extends({
    id: pageRequestUuid4
  }, bannerOverlayInlineStyle(banner)), content);
};

export { Banner };