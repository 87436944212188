import { locationRestConsentPost } from "../wp-api";
import { getOptionsFromWindow } from "../utils";
import { applyConsentForwarding, getCookieConsentManager } from ".";
import { commonRequest } from "@devowl-wp/utils";

/**
 * Save consent to the backend and apply cookies on running session.
 */
async function applyConsent({
  consent,
  markAsDoNotTrack = false,
  buttonClicked,
  blocker = 0,
  blockerThumbnail,
  tcfString
}) {
  const {
    clientWidth,
    clientHeight
  } = document.documentElement;
  const {
    restNamespace,
    restRoot,
    restQuery,
    restNonce
  } = getOptionsFromWindow(); // Use `commonRequest` cause on frontend we do not have `wp.i18n` available

  const {
    forward,
    uuid,
    cookie_revision,
    decision_in_cookie
  } = await commonRequest({
    location: locationRestConsentPost,
    options: {
      restNamespace,
      restRoot,
      restQuery,
      restNonce
    },
    sendRestNonce: false,
    request: { ...consent,
      markAsDoNotTrack,
      buttonClicked,
      viewPortWidth: clientWidth,
      viewPortHeight: clientHeight,
      blocker,
      blockerThumbnail,
      tcfString
    },
    params: {
      _wp_http_referer: window.location.href
    }
  }); // Does the browser support cookies? Save the decision locally in `window` so `getUserDecision` can fallback

  window.rcbDisabledCookieComp = {
    uuid,
    previousUuids: [],
    revision: cookie_revision,
    consent: decision_in_cookie
  };
  await Promise.all([// Apply consent forwarding and wait for all requests
  forward && applyConsentForwarding(forward), getCookieConsentManager().applyCookies({
    type: "consent"
  })].filter(Boolean));
}

export { applyConsent };