import { matchingSelector } from "@devowl-wp/resolve-none-computed-style";
/**
 * Port of `jQuery.parents()`.
 */

function parents(element, selector) {
  const elements = [];
  let elem = element.parentElement;

  while (elem !== null) {
    if (elem.nodeType === Node.ELEMENT_NODE && matchingSelector(elem, selector)) {
      elements.push(elem);
    }

    elem = elem.parentElement;
  }

  return elements;
}

export { parents };