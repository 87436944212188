/**
 * A PHP's port of `explode`. `.split` is not similar because it does not join
 * remaining string.
 */
function explode(string, separator, n) {
  const split = string.split(separator);
  if (split.length <= n) return split;
  const out = split.slice(0, n - 1);
  out.push(split.slice(n - 1).join(separator));
  return out;
}

export { explode };