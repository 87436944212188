import { getOtherOptionsFromWindow } from "../utils";

const BannerProductionNotice = () => {
  const {
    isPro,
    isLicensed,
    isDevLicense,
    bannerI18n: {
      noLicense,
      devLicense
    }
  } = getOtherOptionsFromWindow();

  if (!isPro) {
    return null;
  }

  let useText = "";

  if (isDevLicense) {
    useText = devLicense;
  } else if (!isLicensed) {
    useText = noLicense;
  }

  return useText ? h("div", {
    style: {
      fontSize: 13,
      color: "#c95252",
      fontWeight: "bold",
      order: 9
    }
  }, useText) : null;
};

export { BannerProductionNotice };