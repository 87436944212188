import { getOtherOptionsFromWindow } from "../utils";
import { getUserDecision, regExpPatternFromWildcardName } from "@devowl-wp/cookie-consent-web-client";
/**
 * Synchronously check if a consent is given. See `window.consentApi.consent` for more documentation.
 */

function consentSync(typeOrId, name,
/**
 * This can also be `*` to allow all hosts.
 */
host) {
  // Find matching cookie
  const found = [];
  const {
    groups,
    revisionHash
  } = getOtherOptionsFromWindow();
  const allCookies = groups.map(({
    items
  }) => items).flat();

  for (const row of allCookies) {
    if (typeof typeOrId === "number") {
      if (row.id === typeOrId) {
        found.push({
          cookie: row,
          relevance: 10
        });
      }
    } else {
      const {
        technicalDefinitions
      } = row;

      if (technicalDefinitions !== null && technicalDefinitions !== void 0 && technicalDefinitions.length) {
        // Check if technical information matches
        for (const td of technicalDefinitions) {
          const regexp = regExpPatternFromWildcardName(td.name);

          if (td.type === typeOrId && (td.name === name || name.match(regexp)) && (td.host === host || host === "*")) {
            found.push({
              cookie: row,
              // Create a priority by "relevance" inside the technical definitions
              // This is the case if e.g. another Cookie consumes the same technical cookie
              // Example: Vimeo uses Facebook Pixel, too
              relevance: technicalDefinitions.length + technicalDefinitions.indexOf(td) + 1
            });
            break;
          }
        }
      }
    }
  }

  const already = getUserDecision(getOtherOptionsFromWindow().userConsentCookieName);

  if (found.length) {
    const relevantCookie = found.sort(({
      relevance: a
    }, {
      relevance: b
    }) => a - b)[0].cookie; // Check if consent is already given

    if (already && revisionHash === already.revision) {
      const consentCookieIds = Object.values(already.consent).flat();

      if (consentCookieIds.indexOf(relevantCookie.id) > -1) {
        return {
          cookie: relevantCookie,
          consentGiven: true,
          cookieOptIn: true
        };
      } else {
        return {
          cookie: relevantCookie,
          consentGiven: true,
          cookieOptIn: false
        };
      }
    } else {
      return {
        cookie: relevantCookie,
        consentGiven: false,
        cookieOptIn: false
      };
    }
  } else {
    return {
      cookie: null,
      consentGiven: !!already,
      cookieOptIn: true
    };
  }
}

export { consentSync };