/**
 * Checks if the given banner is not visible due to legal settings.
 */
function isHiddenDueLegal(banner) {
  const {
    legal: {
      imprint,
      imprintHide,
      privacyPolicy,
      privacyPolicyHide
    },
    pageId
  } = banner;
  return [imprintHide && +imprint, privacyPolicyHide && +privacyPolicy].filter(Boolean).indexOf(pageId) > -1;
}

export { isHiddenDueLegal };