import { consent } from ".";
/**
 * `window.consentApi.consentAll`: Check if a set of given technical information (e.g. HTTP Cookie, LocalStorage, ...)
 * have a consent. This is similar to `window.consentApi.consent` but for multiple cookies.
 */

function consentAll(technicalDefinitions) {
  return Promise.all(technicalDefinitions.map(args => consent(...args)));
}

export { consentAll };