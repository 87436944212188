import _extends from "@babel/runtime/helpers/extends";
import { useBanner, BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER, usePlainCss, scaleMobile, bannerInlineStylesRightSide, bannerInlineStylesLeftSide, bannerInlineStyleBody, bannerInlineStyleBodyContainer, bannerInlineStyleBodyDescription, commonInlineStyleBodyTeaching, commonInlineStyleBodyTeachingsSeparator, BannerDottedGroupList, BannerTcfStackList, BannerGroupList, BannerTcfGroupList, BannerButtonList, BannerHistorySelect, useCommonTeachings } from "../../..";
import { useMemo, Fragment } from "react";

const BannerBody = ({
  leftSideContainerRef,
  rightSideContainerRef
}) => {
  var _document$getElementB;

  const banner = useBanner();
  const {
    layout: {
      type
    },
    mobile,
    bodyDesign: {
      teachingsSeparatorActive
    },
    tcf,
    individualPrivacyOpen,
    activeAction,
    pageRequestUuid4
  } = banner;
  const {
    description,
    teachings
  } = useCommonTeachings(); // Make the content responsive

  const leftSideId = "bnnr-body-leftSide-".concat(pageRequestUuid4);
  const rightSideId = "bnnr-body-rightSide-".concat(pageRequestUuid4);
  const contentId = "cntnt-".concat(pageRequestUuid4);
  usePlainCss(" #".concat(leftSideId, " img {\n  max-width: 100%;\n  height: auto;\n}\n\n").concat(((_document$getElementB = document.getElementById(pageRequestUuid4)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.clientWidth) > BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER ? "" : "#".concat(leftSideId, " {\n    float: initial !important;\n    width: auto !important;\n    padding-right: initial !important;\n    ").concat(scaleMobile(mobile, pageRequestUuid4, type, "banner") === "banner" && !individualPrivacyOpen ? "padding-bottom: 10px !important;" : individualPrivacyOpen ? "padding-top: 10px !important;" : "", "\n    }\n\n    #").concat(contentId, " {\n        min-height: calc(var(--rendered-height-header) + var(--rendered-height-footer) + var(--rendered-height-rightSideContainer) + 60px);\n    }\n    \n    #").concat(rightSideId, " {\n    float: initial !important;\n    width: auto !important;\n    max-width: initial !important;\n    margin: initial !important;\n    ").concat(individualPrivacyOpen ? "padding-bottom: 5px !important;" : "padding-top: 10px !important;", "\n    position: sticky;\n    }"))); // Calculate the body content depending on TCF availability and if second view got opened

  const stackOrGroups = useMemo(() => {
    if (process.env.IS_TCF === "1" && tcf) {
      return individualPrivacyOpen ? h(BannerTcfGroupList, null) : h(BannerTcfStackList, null);
    } else {
      return individualPrivacyOpen ? h(BannerGroupList, null) : h(BannerDottedGroupList, null);
    }
  }, [individualPrivacyOpen, tcf]);
  const leftSide = h("div", _extends({
    ref: leftSideContainerRef,
    key: "leftSide",
    id: leftSideId
  }, bannerInlineStylesLeftSide(banner)), h("div", bannerInlineStyleBodyDescription(banner), activeAction === "history" ? h(BannerHistorySelect, null) : h(Fragment, null, h("span", {
    dangerouslySetInnerHTML: {
      __html: description
    }
  }), teachings.length > 0 && h(Fragment, null, teachingsSeparatorActive && h("div", null, h("span", commonInlineStyleBodyTeachingsSeparator(banner))), teachings.map(teaching => h("span", _extends({
    key: teaching
  }, commonInlineStyleBodyTeaching(banner), {
    dangerouslySetInnerHTML: {
      __html: teaching
    }
  })))))), stackOrGroups);
  const rightSide = activeAction === "history" ? h("div", {
    ref: rightSideContainerRef
  }) : h("div", _extends({
    ref: rightSideContainerRef,
    key: "rightSide",
    id: rightSideId
  }, bannerInlineStylesRightSide(banner)), h(BannerButtonList, null));
  return h("div", bannerInlineStyleBodyContainer(banner), h("div", bannerInlineStyleBody(banner), individualPrivacyOpen ? [rightSide, leftSide] : [leftSide, rightSide], h("div", {
    style: {
      clear: "both"
    }
  })));
};

export { BannerBody };