import _extends from "@babel/runtime/helpers/extends";
import { useCallback } from "react";
import { useState, useMemo } from "react";
import { isIE, AsciiSpinner, useBanner, bannerButtonsInlineStyleAcceptAll, bannerButtonsInlineStyleAcceptEssentials, bannerButtonsInlineStyleAcceptIndividual, bannerButtonsInlineStyleSave } from "../../../..";

const BannerButton = ({
  inlineStyle,
  type,
  onClick,
  children,
  framed,
  busyOnClick,
  order
}) => {
  if (type === "hide") {
    return null;
  }

  const [isBusy, setIsBusy] = useState(false);
  const handleOnClick = useCallback(() => {
    if (!isBusy) {
      busyOnClick && setIsBusy(true);
      onClick === null || onClick === void 0 ? void 0 : onClick();
    }
  }, [onClick, isBusy, busyOnClick]);
  const [isHover, setIsHover] = useState(false);
  const banner = useBanner();
  const {
    individualPrivacyOpen,
    decision: {
      acceptAll,
      acceptEssentials
    },
    bodyDesign: {
      acceptAllOneRowLayout,
      acceptEssentialsUseAcceptAll
    }
  } = banner;
  const useAcceptEssentials = acceptEssentialsUseAcceptAll ? acceptAll : acceptEssentials;
  const interactionAttr = {
    onClick: handleOnClick,
    onMouseEnter: () => setIsHover(true),
    onMouseLeave: () => setIsHover(false),
    style: {
      cursor: "pointer"
    }
  };
  const useStyleFn = useMemo(() => {
    switch (inlineStyle) {
      case "acceptEssentials":
        return bannerButtonsInlineStyleAcceptEssentials;

      case "acceptIndividual":
        return bannerButtonsInlineStyleAcceptIndividual;

      case "save":
        return bannerButtonsInlineStyleSave;

      default:
        return bannerButtonsInlineStyleAcceptAll;
    }
  }, [inlineStyle]); // Also create a spacing `height:10` container because `marginBottom` causes issues with `position:sticky`

  return h("div", _extends({}, type === "button" ? interactionAttr : {}, useStyleFn(banner, isHover, framed, order, // IE does not support flex with calc()
  isIE() || acceptAll === "hide" || useAcceptEssentials === "hide" || individualPrivacyOpen || order > 1 ? false : acceptAllOneRowLayout)), h("span", type === "link" ? interactionAttr : {}, isBusy ? h(AsciiSpinner, null) : children));
};

export { BannerButton };