import { scaleMobile } from "../..";
import { calculateRgba } from "../../..";
/**
 * This method needs to be ported to Banner.php#wp_footer, too!
 */

function bannerOverlayInlineStyle({
  layout: {
    type,
    overlay,
    overlayBg,
    overlayBgAlpha
  },
  design: {
    fontSize
  },
  customCss: {
    antiAdBlocker
  },
  mobile,
  pageRequestUuid4
}) {
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-bann3r rcb-bann3r-".concat(scaleMobile(mobile, pageRequestUuid4, type, "banner"), " ").concat(overlay ? "" : "overlay-deactivated"),
    style: {
      background: overlay ? calculateRgba(overlayBg, overlayBgAlpha) : "none",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999999,
      pointerEvents: overlay ? "all" : "none",
      fontSize: scaleMobile(mobile, pageRequestUuid4, +fontSize),
      filter: "none",
      maxHeight: "100vh"
    }
  };
}

export { bannerOverlayInlineStyle };