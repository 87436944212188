/* istanbul ignore file: we do not need to care about the entry point file as errors are detected through integration tests (E2E) */

/**
 * Entrypoint for plain website. This file is only loaded if the visitor needs to accept cookies.
 */
import { render } from "react-dom";
import { WebsiteBanner } from "./components";
import { getOtherOptionsFromWindow, ensureBodyElement } from "./utils";
import { listenPresetOptIn, deleteCookiesServer, getCookieConsentManager } from "./others";
import { tcfCmpApi } from "./tcf";
import * as API from "./api";
import { getWebpackPublicPath } from "@devowl-wp/utils";
import { printConsentUuidsToHTMLElement } from "@devowl-wp/cookie-consent-web-client";
import { OPT_OUT_ALL_EVENT, ready } from "@devowl-wp/cookie-consent-web-client";
import { getSsrPoweredByLink } from "@devowl-wp/react-cookie-banner";
__webpack_public_path__ = getWebpackPublicPath(process.env.slug);
listenPresetOptIn();
tcfCmpApi();
ready(() => {
  const {
    pageRequestUuid4
  } = getOtherOptionsFromWindow();
  const poweredLink = getSsrPoweredByLink("".concat(pageRequestUuid4, "-powered-by"));
  const element = ensureBodyElement(document.getElementById(pageRequestUuid4));
  printConsentUuidsToHTMLElement(".rcb-consent-history-uuids", getCookieConsentManager());
  document.addEventListener(OPT_OUT_ALL_EVENT, async ({
    detail: {
      deleteHttpCookies
    }
  }) => {
    // Handle cookie deletion which needs to be done server-side (e. g. HTTP cookies)
    deleteHttpCookies.length && setTimeout(() => deleteCookiesServer(deleteHttpCookies), 0);
  });

  if (element) {
    render(h(WebsiteBanner, {
      poweredLink: poweredLink
    }), element);
  }
}, "interactive"); // Expose public API

if (window) {
  window.consentApi = API;
}

export * from "./api";